import ASB from '../../images/apple.png'
import GPSB from '../../images/google.png'
import STAR from '../../images/stars.png'
import TEST from '../../images/banner.png'
import BH from '../../images/banner_header.png'
import '../main/main.css'
import '../products/products.css'
import Footer from "../../components/footer/Footer";

export default function Products() {
    const hasWindow = typeof window !== 'undefined';
    const width = hasWindow ? window.innerWidth : null;
    const badgeWidth = width / 4;
    function handleAndroidClick (e) {
        window.dataLayer.push({
          event: 'event',
          eventProps: {
              category: 'applink',
              action: 'click',
              label: 'android',
              value: 'https://play.google.com/store/apps/details?id=com.hotcakesolutions.dkb&pli=1'
          }
        });
      };

    function handleIOSClick (e) {
        window.dataLayer.push({
        event: 'event',
        eventProps: {
          category: 'applink',
          action: 'click',
          label: 'ios',
          value: 'https://apps.apple.com/kr/app/drinking-buddy/id1463317609'
        }
        });
    };
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'Products Page'
      }
    });
    return (
        <div>
            <div className="dkbContainer">
                <div className="productsContainer">
                    <div className="banner">
                        <div className="banner-header">
                            <div className="up">
                                <img src={BH} alt="Drinking buddy header" className="bh-img"/>
                            </div>
                            <div className="down">
                                <div className="links">
                                    <p className="imglinks">
                                        <a href="https://apps.apple.com/kr/app/drinking-buddy/id1463317609" onClick={handleIOSClick}>
                                            <img src={ASB} alt="App Store Badge" className="badgeStore" width={badgeWidth} />
                                        </a>
                                    </p>
                                    <p className="imglinks">
                                        <a href="https://play.google.com/store/apps/details?id=com.hotcakesolutions.dkb&pli=1"
                                        onClick={handleAndroidClick}>
                                            <img src={GPSB} alt="Google Play Store Badge" className="badgeStore" width={badgeWidth} />
                                        </a>
                                    </p>
                                </div>
                                <img src={STAR} alt="star" className="star"/>
                            </div>
                        </div>
                    </div>
                    <div className="" style={{ overflow: 'hidden' }}>
                      <img
                        style={{
                          width: '100%'
                        }}
                        src={TEST}
                        alt="intro"
                        className="intro"
                      />
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}